import { useParams, useSearchParams } from '@solidjs/router';
import { Component, createMemo, createSignal, For, onMount, Show } from 'solid-js';
import IconGovernmentId from '~/assets/icons/userId.svg';
import brokenImage from '~/assets/images/brokenImage.svg';
import {
  BANK_STATEMENT,
  DATE_OF_BIRTH,
  DESIRED_MOVE_IN_DATE,
  EMAIL,
  FIRST_NAME,
  GOVERNMENT_ID,
  LAST_NAME,
  PHONE,
  SSN,
  UPLOAD_BANK_STATEMENT,
  UPLOAD_GOVERNMENT_ID,
} from '~/assets/strings';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import LabeledGroup from '~/components/common/Inputs/LabeledGroup';
import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import LabeledTextInput from '~/components/common/Inputs/LabeledTextInput';
import { TintedSVG } from '~/components/common/TintedSVG';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import { DropZone } from '~/components/common/forms/Dropzone';
import { IconX } from '~/components/ui';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useStyling } from '~/global-contexts/CompanyStyling';
import { useErrorMessage } from '~/hooks/useErrorMessage';
import { useLocalization } from '~/hooks/useLocalization';
import { StepFooter } from '~/pages/rental-application/steps/StepFooter';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { StepError } from '~/state/mainAppState';
import { CommentSection, FileDescriptor, FileType } from '~/types/RentalApplication';
import { RentalApplicationSteps } from '~/types/RentalApplicationSteps';
import { RemoveRentalApplicationFileUseCase } from '~/use-cases/rental-applications/application-steps/removeRentalApplicationFileUseCase';
import { UpdateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/updateRentalApplicationInfoUseCase';
import { ValidateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/validateRentalApplicationInfoUseCase';
import { GetRentalApplicationUseCase } from '~/use-cases/rental-applications/getRentalApplicationUseCase';
import { GoToNextStepUseCase } from '~/use-cases/rental-applications/goToNextStepUseCase';
import { SetUnitWithIdUseCase } from '~/use-cases/rental-applications/initUnitFromUrlUseCase';
import { isImage, isPdf } from '~/utils/fileType';
import { uuid } from '~/utils/tools';

export const ApplicantInformationStep: Component = () => {
  const { t } = useLocalization();
  const params = useParams();
  const { companyStyling } = useStyling();
  const { getErrorMessage } = useErrorMessage();
  const [files, setFiles] = createSignal<FileDescriptor[]>([]);
  const [isGoingToNextStep, setIsGoingToNextStep] = createSignal(false);
  const [minDesiredMoveInDate, setMinDesiredMoveInDate] = createSignal<string>('');
  const [searchParams] = useSearchParams<{ unitId: string }>();
  const [hasConsented, setHasConsented] = createSignal(false);
  const [showConsentError, setShowConsentError] = createSignal(false);

  let govIdRef: HTMLInputElement | undefined;
  let bankStatementRef: HTMLInputElement | undefined;
  let firstNameRef: HTMLInputElement | undefined;
  let lastNameRef: HTMLInputElement | undefined;
  let emailRef: HTMLInputElement | undefined;
  let phoneRef: HTMLInputElement | undefined;
  let ssnRef: HTMLInputElement | undefined;
  let dateOfBirthRef: HTMLInputElement | undefined;
  let desiredMoveInDateRef: HTMLInputElement | undefined;
  let commentRef: HTMLTextAreaElement | undefined;

  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: getRentalApplication, isLoading: isLoadingApplication } = useUseCase(GetRentalApplicationUseCase);
  const { execute: updateRentalApplication, isLoading: isUpdatingApplication } = useUseCase(UpdateRentalApplicationInfoUseCase);
  const { execute: removeFiles } = useUseCase(RemoveRentalApplicationFileUseCase);
  const { execute: goToNextStep } = useUseCase(GoToNextStepUseCase);
  const { execute: validateRentalApplication, didSucceed: didSucceedValidation } = useUseCase(ValidateRentalApplicationInfoUseCase);
  const { execute: setUnitId } = useUseCase(SetUnitWithIdUseCase);
  const errorCodes = createMemo(() => {
    return (presentableRentalApplication()?.errors[RentalApplicationSteps.APPLICANT_INFORMATION] ?? {}) as StepError;
  });

  const getMinDesiredMoveInDate = () => {
    const today = new Date().toISOString().split('T')[0];
    setMinDesiredMoveInDate(today);
  };

  onMount(async () => {
    await getRentalApplication({ id: params.id, password: params.password });
    setUnitId(searchParams.unitId);
    getMinDesiredMoveInDate();
  });

  function handleFiles(type: string, fileList?: FileList | null) {
    const newImages: FileDescriptor[] = [];
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (!isImage(file) && !isPdf(file)) {
          continue;
        }
        const id = uuid();
        const fileDescriptor: FileDescriptor = {
          id,
          type: type as FileType,
          file,
          name: file.name,
          wasUploaded: false,
          isImage: file.type.includes('image'),
        };
        newImages.push(fileDescriptor);
      }
    }
    setFiles([...files(), ...newImages]);
    updateRentalApplication({ files: files() });
  }

  const removeApplicationFile = async (id: string) => {
    await removeFiles(id);
    setFiles(files().filter((file) => file.id !== id));
    if (govIdRef?.value) {
      govIdRef.value = '';
    }
    if (bankStatementRef?.value) {
      bankStatementRef.value = '';
    }
  };

  const validateApplicantInfo = async () => {
    await validateRentalApplication();

    if (!hasConsented()) {
      setShowConsentError(true);
      return false;
    }

    if (!didSucceedValidation() || presentableRentalApplication()?.hasRentalApplicationInfoError) {
      return false;
    }

    return true;
  };

  const onNext = async () => {
    if (!(await validateApplicantInfo())) return;
    setIsGoingToNextStep(true);
    await goToNextStep({
      currentStep: RentalApplicationSteps.APPLICANT_INFORMATION,
      credentials: { id: params.id, password: params.password },
    });
    setIsGoingToNextStep(false);
  };

  const updateInfo = (fieldName: string) => {
    updateRentalApplication({
      firstName: firstNameRef?.value,
      lastName: lastNameRef?.value,
      email: emailRef?.value,
      phone: phoneRef?.value,
      ssn: ssnRef?.value,
      dateOfBirth: dateOfBirthRef?.value,
      desiredMoveInDate: desiredMoveInDateRef?.value,
      comments: RentalApplicationPresenter.setComment(
        presentableRentalApplication()?.comments,
        CommentSection.ApplicantInformation,
        commentRef?.value || ''
      ),
    });
    validateRentalApplication(fieldName);
  };

  const maxBirthDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return date.toISOString().split('T')[0];
  };

  return (
    <Show
      when={!isLoadingApplication() && !isGoingToNextStep()}
      fallback={<div class="flex h-section3 flex-1 grow ">{isUpdatingApplication() ? <ActivityIndicator /> : <ActivityIndicator />}</div>}>
      <div class="flex flex-col">
        <div class="flex-1 overflow-y-auto p-6">
          <div class="grid grid-cols-2 gap-6 py-4">
            <LabeledTextInput
              label={t(FIRST_NAME)}
              required
              error={getErrorMessage(errorCodes().firstName)}
              ref={firstNameRef}
              value={presentableRentalApplication()?.firstName || ''}
              onChange={() => updateInfo('firstName')}
            />
            <LabeledTextInput
              label={t(LAST_NAME)}
              required
              error={getErrorMessage(errorCodes().lastName)}
              ref={lastNameRef}
              value={presentableRentalApplication()?.lastName || ''}
              onChange={() => updateInfo('lastName')}
            />
            <LabeledTextInput
              label={t(EMAIL)}
              required
              error={getErrorMessage(errorCodes().email)}
              ref={emailRef}
              value={presentableRentalApplication()?.email || ''}
              onChange={() => updateInfo('email')}
            />
            <LabeledTextInput
              label={t(PHONE)}
              required
              error={getErrorMessage(errorCodes().phone)}
              ref={phoneRef}
              value={presentableRentalApplication()?.phoneNumber || ''}
              onChange={() => updateInfo('phone')}
            />
            <LabeledTextInput
              label={t(SSN)}
              required
              error={getErrorMessage(errorCodes().ssn)}
              ref={ssnRef}
              value={presentableRentalApplication()?.ssn || ''}
              onChange={() => updateInfo('ssn')}
              maxLength={11}
            />
            <LabeledTextInput
              type="date"
              required
              label={t(DATE_OF_BIRTH)}
              error={getErrorMessage(errorCodes().dateOfBirth)}
              ref={dateOfBirthRef}
              value={presentableRentalApplication()?.dateOfBirth || ''}
              onChange={() => updateInfo('dateOfBirth')}
              max={maxBirthDate()}
              inputClass="date-input-ios"
            />
            <LabeledTextInput
              type="date"
              required
              label={t(DESIRED_MOVE_IN_DATE)}
              error={getErrorMessage(errorCodes().desiredMoveInDate)}
              ref={desiredMoveInDateRef}
              value={presentableRentalApplication()?.desiredMoveInDate || ''}
              onChange={() => updateInfo('desiredMoveInDate')}
              inputClass="date-input-ios"
              min={minDesiredMoveInDate()}
            />
          </div>
          <div class="mt-2 grid grid-cols-2 gap-5 py-4">
            <LabeledGroup class="col-span-full sm:col-span-1" label={t(GOVERNMENT_ID)}>
              <div class={`flex ${!presentableRentalApplication()?.governmentIdFiles ? 'flex-row' : 'flex-col'} gap-4`}>
                <Show when={presentableRentalApplication()?.governmentIdFiles?.length === 0}>
                  <DropZone
                    class="hover:bg-primary-color/10 flex h-28 cursor-pointer flex-col items-center justify-center rounded-lg border border-dashed border-primary-color pt-3"
                    activeClass="bg-primary-color/5"
                    onDropFiles={(files?: FileList) => handleFiles(FileType.IDENTIFICATION, files)}
                    onClick={() => govIdRef?.click()}>
                    <TintedSVG svg={IconGovernmentId} color="var(--primary-color)" />
                    <div class="text-sm font-medium text-primary-color"> {t(UPLOAD_GOVERNMENT_ID)}</div>
                    <input
                      type="file"
                      hidden
                      ref={govIdRef}
                      accept="image/*,.pdf"
                      onChange={(e) => handleFiles(FileType.IDENTIFICATION, e.target.files)}
                    />
                  </DropZone>
                </Show>
              </div>
              <Show when={presentableRentalApplication()?.governmentIdFiles}>
                <For each={presentableRentalApplication()?.governmentIdFiles}>
                  {(file) => (
                    <div class="text-center">
                      {file.isImage ? (
                        <div class="relative mx-1 mb-1 inline-block size-40 cursor-pointer">
                          <img
                            class="size-full cursor-pointer rounded-lg border border-black bg-white object-cover"
                            src={file.path}
                            onError={(e) => {
                              (e.target as HTMLImageElement).src = brokenImage;
                            }}
                          />
                          <div
                            class="absolute right-4 top-5 -mr-2 -mt-3 flex size-6 items-center justify-center rounded-full border border-border-level01 bg-white  hover:bg-inputbox-bg"
                            onClick={() => removeApplicationFile(file.id)}>
                            <IconX aria-label="Close" class="size-4 text-text-level03" />
                          </div>
                        </div>
                      ) : (
                        <div class="relative rounded-md bg-input py-2">
                          <div class="w-[calc(100%-32px)] truncate px-2 text-sm font-medium">{file.name}</div>
                          <div
                            class="absolute right-4 top-5 -mr-2 -mt-3 flex size-6 cursor-pointer items-center justify-center rounded-full border border-border-level01 bg-white  hover:bg-inputbox-bg"
                            onClick={() => removeApplicationFile(file.id)}>
                            <IconX aria-label="Close" class="size-4 text-text-level03" />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </For>
              </Show>
            </LabeledGroup>
            <LabeledGroup class="col-span-full sm:col-span-1" label={t(BANK_STATEMENT)}>
              <div class={`flex ${!presentableRentalApplication()?.bankStatementFiles ? 'flex-row' : 'flex-col'} gap-4`}>
                <Show when={presentableRentalApplication()?.bankStatementFiles?.length === 0}>
                  <DropZone
                    class="hover:bg-primary-color/10 flex h-28 cursor-pointer flex-col items-center justify-center rounded-lg border border-dashed border-primary-color pt-3"
                    activeClass="bg-primary-color/5"
                    onDropFiles={(files?: FileList) => handleFiles(FileType.BANK_STATEMENT, files)}
                    onClick={() => bankStatementRef?.click()}>
                    <TintedSVG svg={IconGovernmentId} color="var(--primary-color)" />
                    <div class="text-sm font-medium text-primary-color"> {t(UPLOAD_BANK_STATEMENT)}</div>
                    <input
                      type="file"
                      hidden
                      ref={(element) => (bankStatementRef = element)}
                      accept="image/*,.pdf"
                      onChange={(e) => handleFiles(FileType.BANK_STATEMENT, e.target.files)}
                    />
                  </DropZone>
                </Show>
              </div>
              <Show when={presentableRentalApplication()?.bankStatementFiles}>
                <For each={presentableRentalApplication()?.bankStatementFiles}>
                  {(file) => (
                    <div class="text-center">
                      {file.isImage ? (
                        <div class="relative mx-1 mb-1 inline-block size-40 cursor-pointer">
                          <img
                            class="size-full cursor-pointer rounded-lg border border-black bg-white object-cover"
                            src={file.path}
                            onError={(e) => {
                              (e.target as HTMLImageElement).src = brokenImage;
                            }}
                          />
                          <div
                            class="absolute right-4 top-5 -mr-2 -mt-3 flex size-6 items-center justify-center rounded-full border border-border-level01 bg-white  hover:bg-inputbox-bg"
                            onClick={() => removeApplicationFile(file.id)}>
                            <IconX aria-label="Close" class="size-4 text-text-level03" />
                          </div>
                        </div>
                      ) : (
                        <div class="relative rounded-md bg-input py-2">
                          <div class="w-[calc(100%-32px)] truncate px-2 text-sm font-medium">{file.name}</div>
                          <div
                            class="absolute right-4 top-5 -mr-2 -mt-3 flex size-6 cursor-pointer items-center justify-center rounded-full border border-border-level01 bg-white  hover:bg-inputbox-bg"
                            onClick={() => removeApplicationFile(file.id)}>
                            <IconX aria-label="Close" class="size-4 text-text-level03" />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </For>
              </Show>
            </LabeledGroup>
          </div>
          <LabeledTextArea
            label={t('Comment')}
            value={RentalApplicationPresenter.getComment(presentableRentalApplication()?.comments, CommentSection.ApplicantInformation)}
            onInput={() => updateInfo('comment')}
            ref={commentRef}
          />
        </div>
        <div class="px-6 pb-4">
          <div class="mb-2 flex items-start gap-2">
            <Checkbox
              id="consent"
              class="mt-1"
              checked={hasConsented()}
              onInput={(checked) => {
                setHasConsented(checked);
                if (checked) {
                  setShowConsentError(false);
                }
              }}
            />
            <label for="consent" class="text-sm text-text-level03">
              By submitting this form you are giving {companyStyling?.()?.name ?? '--'} you consent to receive account notifications and
              direct communications messages from {companyStyling?.()?.name ?? '--'} at the number provided, including messages sent by
              autodialer. Message frequency may vary. Consent is not a condition of purchase. Msg & data rates may apply. Msg frequency
              varies. Unsubscribe at any time by replying STOP. Reply HELP for help.{' '}
              <a href="/privacy-policy" target="_blank" class="text-primary-color hover:underline">
                Privacy Policy
              </a>{' '}
              &{' '}
              <a href="/terms-of-service" target="_blank" class="text-primary-color hover:underline">
                Terms of service
              </a>
              .
            </label>
          </div>
          <Show when={showConsentError()}>
            <div class="text-sm text-danger">Please accept the terms and conditions to proceed.</div>
          </Show>
        </div>
      </div>
      <StepFooter onNext={onNext} />
    </Show>
  );
};
